import axios from 'axios'

export default {
  data() {
    return {
      environments: [],
    }
  },
  async created() {
    this.environments = (await axios.get('/api/environments')).data
  },
  computed: {
    currentEnvironemnt() {
      return this.environments.find(environment => {
        return environment.storeUrl === this.$env.STORE_URL && environment.cmsUrl === this.$env.CMS_URL
      })
    },
  },
}
